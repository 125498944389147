import axios from 'axios';
import { notification } from 'antd';
import { getRootUrl, getSyncUser } from './index';
import { getCookie } from './cookies';
import bugsnagClient from './bugsnag';
import hardCodeDomainsConfig from '../config/domains'; // @TODO: please take time and remove this configs as they are in backend, just do it by making sure all is ok
import directoryApi from '@tutorbot/directory-api';

const api = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use(
  config => ({
    ...config,
    baseURL: `${getRootUrl()}api/`,
    headers: {
      ...config.headers,
      authtoken: getCookie('token', false) || null,
      onetimetoken: getCookie('oneTimeToken', false) || null,
      locale: getCookie('locale') || null,
      portal: getCookie('portal') || null,
    },
  }),
  error => {
    bugsnagClient.notify(error);
    throw error;
  },
);

api.interceptors.response.use(
  response => response.data,
  error => {
    const errorMetaData =
      error.response && error.response.config
        ? {
          url: error.response.config.url,
          method: error.response.config.method,
          params: error.response.config.params,
          data: error.response.config.data,
        }
        : {};
    bugsnagClient.notify(error, { metaData: errorMetaData });

    const {
      response,
      config: { handler = true },
    } = error;

    const { data } = response || {};

    const { message: responseMessage } = data || {};

    if (handler && process.browser) {
      let { message } = error;
      if (responseMessage) {
        message = responseMessage;
      }

      notification.error({
        message,
      });
    }

    throw error;
  },
);

export const orderCourseByShortIdAndPaymentMethod = async (
  courseId,
  paymentMethod,
  locale,
  scheduleId,
  useSchoolPayment = false,
) =>
  await api.post('orders', {
    items: [{ courseId }],
    paymentMethod,
    locale,
    scheduleId,
    useSchoolPayment,
  });

export const orderClaimRefund = async (orderId, action) => api.post('order/claim-refund', { orderId, action });

export const sendPubSubEvent = async (event, data = {}) => {
  const user = getSyncUser();

  return await api.post('users/events', {
    event,
    payload: {
      user,
      data,
    },
  });
};

export const subscribeToList = async (user, data) => {
  return api.post('user-subscriptions/GDPRSubscribe', {
    user,
    data,
  });
};

export const getPaths = async (portal, competences) => {
  if (competences && competences[0]) {
    return api.get('paths', {
      params: {
        portal,
        competences,
      },
    });
  }
  return api.get('paths', {
    params: {
      portal,
    },
  });
};
export const getCompetences = async () => await api.get(`competences`);
export const getCategoryPaths = async (domain, categoryId) => await api.get(`pathsCategory?portal=${domain}&categoryId=${categoryId}`);
export const getPopularPaths = async domain => await api.get(`popularPaths?portal=${domain}`);
export const getPath = async id => await api.get(`paths/${id}`);
export const getCurrentUser = async _ => api.get('users/currentUser').catch(() => null);

export const getCommunityCategories = async cId => await axios.get(`/forum-categories/${cId}`);
export const getUserRewardTransactions = async _ => await api.get('/users/reward-transactions');

let settingsFromDb = [];
export const getDomainsSettingsFromDb = async () => {
  if (!settingsFromDb.length) {
    settingsFromDb = await directoryApi().settings.all();
  }

  return settingsFromDb;
};

export const getDomainsSettings = async _ => (await getDomainsSettingsFromDb()).map(settingFromDb => {
  if (settingFromDb.domain === 'localhost:3000') {
    settingFromDb.domain = 'localhost';
  }

  const hardCodeDomainConfig = hardCodeDomainsConfig.find(item => settingFromDb.domain === item.domain) || {};

  return { ...settingFromDb, ...hardCodeDomainConfig.settings };
});

export const getDomainSettings = async host => {
  const domain = host.split(',')[0].replace('www.', '').replace(/:\d+/, '');

  return (await getDomainsSettings()).find(item => item.domain === domain)
};

export default api;
