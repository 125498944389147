import React from 'react';
import PropTypes from 'prop-types';
import { getCookie, setCookie } from '../utils/cookies';
import { SettingsContext } from './Settings';

const configTranslations = require('../config/translations');

const blogLocales = {
  hy: 67,
  en: 68,
  de: 69,
  ru: 84,
};

export const LocalizationContext = React.createContext();

export const LocalizationProvider = ({ children, locale, locales, blogLocale }) => {
  const {
    domainSettings: { translationPriority = ['hy', 'ru', 'en', 'de'] },
  } = React.useContext(SettingsContext);

  const [state, setState] = React.useState({
    blogLocale,
    locales,
    locale,
  });

  if (state.locale !== locale) {
    state.blogLocale = blogLocales[locale];
    state.locale = locale;
    setState({ ...state });
  }

  const t = label => configTranslations[locale][label] || label;

  const translate = (data, key) => {
    if (data) {
      if (data[`${key}_${locale}`]) {
        return data[`${key}_${locale}`];
      }

      if (data[key]) {
        return data[key];
      }

      // eslint-disable-next-line
      for (const language of translationPriority) {
        if (data[`${key}_${language}`]) {
          return data[`${key}_${language}`];
        }
      }

      return data[key];
    }

    return '';
  };

  const getDefaultValue = (data, key) => {
    if (data) {
      if (data[key]) {
        return data[key];
      }

      // eslint-disable-next-line
      for (const language of translationPriority) {
        if (data[`${key}_${language}`]) {
          return data[`${key}_${language}`];
        }
      }
    }

    return '';
  };

  return (
    <LocalizationContext.Provider value={{ ...state, t, translate, getDefaultValue }}>
      {children}
    </LocalizationContext.Provider>
  );
};

export const withLocalization = WrappedComponent =>
  class extends React.PureComponent {
    render() {
      return (
        <LocalizationContext.Consumer>
          {props => <WrappedComponent {...props} {...this.props} />}
        </LocalizationContext.Consumer>
      );
    }
  };

LocalizationProvider.propTypes = {
  children: PropTypes.any,
  locale: PropTypes.string,
  blogLocale: PropTypes.number.isRequired,
  locales: PropTypes.array.isRequired,
};

LocalizationProvider.defaultProps = {
  children: null,
  locale: null,
};

LocalizationProvider.getInitialProps = async (
  ctx,
  { domainSettings: { defaultLocale, locales } },
) => {
  const {
    query: { locale: ctxLocale },
    res,
  } = ctx;

  const cookieLocation = getCookie('locale');

  let locale = defaultLocale || 'en';

  if (locales) {
    if (locales.includes(ctxLocale)) {
      locale = ctxLocale;
    } else if (locales.includes(cookieLocation)) {
      locale = cookieLocation;
    }
  }

  const blogLocale = blogLocales[locale];

  setCookie('locale', locale);

  if (ctxLocale && !locales.includes(ctxLocale)) {
    res.statusCode = 404;
  }

  return {
    locale,
    blogLocale,
    locales,
  };
};
