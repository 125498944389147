import React from 'react';
import bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import BugsnagPluginExpress from '@bugsnag/plugin-express';
import getConfig from 'next/config';
import { version } from '../package.json';

// defaulting to dummy values to get storybooks working - development env only
const defaultBugsnagValues = {
  serverRuntimeConfig: { BUGSNAG_API_KEY: 'asdqwe' },
  publicRuntimeConfig: { BUGSNAG_API_KEY: 'asdqwe' },
};

const { serverRuntimeConfig, publicRuntimeConfig } = getConfig() || defaultBugsnagValues;

const bugsnagClient = bugsnag({
  apiKey: serverRuntimeConfig.BUGSNAG_API_KEY || publicRuntimeConfig.BUGSNAG_API_KEY,
  appVersion: version,
  plugins: [BugsnagPluginReact, BugsnagPluginExpress],
  releaseStage: process.env.NODE_ENV || 'development',
  enabledReleaseStages: ['production'],
  beforeSend: function (report) {
    // ignore 404 response having API call reporting
    if (report.errorMessage === 'Request failed with status code 404') report.ignore();
  }
});

bugsnagClient.use(BugsnagPluginReact, React);

export default bugsnagClient;
