module.exports = [
  {
    domain: 'localhost',
    settings: {
      region: 'AM',
      defaultLocale: 'hy',
      defaultCurrency: 'AMD',
      blogCountry: 46,
      prices: {
        min: 0,
        max: 200000,
        step: 1000,
      },
      facebook: {
        appId: 283862149617208,
        pageId: 222509821518237,
        testLink: 'https://m.me/language.assessment.bot',
        pageLink: 'https://www.facebook.com/course.am/',
      },
      google: {
        clientId: '1006960196206-9habfpr3d99uhlsrgcj9l2bh02rh3m1o.apps.googleusercontent.com',
      },
      logoUrl: '/static/images/logo.course.am.dark.png',
      phonePlaceholder: '+374 ...',
      translationPriority: ['hy', 'en', 'ru'],
      locales: ['hy', 'en', 'ru'],
      timezone: 'Asia/Yerevan',
    },
  },
  {
    domain: '127.0.0.1',
    settings: {
      region: 'DE',
      defaultLocale: 'en',
      defaultCurrency: 'EUR',
      blogCountry: 47,
      prices: {
        min: 0,
        max: 10000,
        step: 100,
      },
      facebook: {
        appId: 283862149617208,
        pageId: 222509821518237,
        testLink: 'https://m.me/language.assessment.bot',
        pageLink: 'https://www.facebook.com/tutor.berlin/',
      },
      logoUrl: '/static/images/logo_coursede.svg',
      phonePlaceholder: '+49 ...',
      translationPriority: ['de', 'en', 'ru'],
      locales: ['de', 'en', 'ru'],
      timezone: 'Europe/Berlin',
    },
  },
  {
    domain: 'test.course.am',
    settings: {
      region: 'AM',
      indexation: true,
      defaultLocale: 'hy',
      defaultCurrency: 'AMD',
      blogCountry: 46,
      prices: {
        min: 0,
        max: 200000,
        step: 1000,
      },
      facebook: {
        appId: 318307371862716,
        pageId: 222509821518237,
        testLink: 'https://m.me/language.assessment.bot',
        pageLink: 'https://www.facebook.com/course.am/',
      },
      google: {
        clientId: '1006960196206-9habfpr3d99uhlsrgcj9l2bh02rh3m1o.apps.googleusercontent.com',
      },
      logoUrl: '/static/images/logo.course.am.dark.png',
      phonePlaceholder: '+374 ...',
      translationPriority: ['hy', 'en', 'ru'],
      esHost: 'test.course.am',
      locales: ['hy', 'en', 'ru'],
      timezone: 'Asia/Yerevan',
    },
  },
  {
    domain: 'course.am',
    settings: {
      region: 'AM',
      indexation: true,
      defaultLocale: 'hy',
      defaultCurrency: 'AMD',
      blogCountry: 46,
      prices: {
        min: 0,
        max: 200000,
        step: 1000,
      },
      facebook: {
        appId: 318307371862716,
        pageId: 222509821518237,
        testLink: 'https://m.me/language.assessment.bot',
        pageLink: 'https://www.facebook.com/course.am/',
      },
      google: {
        clientId: '1006960196206-9habfpr3d99uhlsrgcj9l2bh02rh3m1o.apps.googleusercontent.com',
      },
      logoUrl: '/static/images/logo.course.am.dark.png',
      phonePlaceholder: '+374 ...',
      translationPriority: ['hy', 'en', 'ru'],
      esHost: 'www.course.am',
      locales: ['hy', 'en', 'ru'],
      timezone: 'Asia/Yerevan',
    },
  },
  {
    domain: 'course.de',
    settings: {
      region: 'DE',
      indexation: true,
      defaultLocale: 'en',
      defaultCurrency: 'EUR',
      blogCountry: 47,
      prices: {
        min: 0,
        max: 10000,
        step: 100,
      },
      facebook: {
        appId: 2335836896628682,
        pageId: 361040944409784,
        testLink: 'https://m.me/language.assessment.bot',
        pageLink: 'https://www.facebook.com/tutor.berlin/',
      },
      google: {
        clientId: '1006960196206-g9sctn3vahtd33lcqn4j6qhd7dabnol0.apps.googleusercontent.com',
      },
      logoUrl: '/static/images/logo_coursede.svg',
      phonePlaceholder: '+49 ...',
      translationPriority: ['de', 'en', 'ru'],
      esHost: 'www.course.de',
      locales: ['de', 'en', 'ru'],
      timezone: 'Europe/Berlin',
    },
  },
  {
    domain: 'course.ru',
    settings: {
      region: 'RU',
      indexation: true,
      defaultLocale: 'ru',
      defaultCurrency: 'RUB',
      blogCountry: 208,
      prices: {
        min: 0,
        max: 50000,
        step: 100,
      },
      facebook: {
        appId: 244118823339632,
        pageId: 104735134563714,
        testLink: 'https://m.me/language.assessment.bot',
        pageLink: 'https://www.facebook.com/course.ru/',
      },
      google: {
        clientId: '1006960196206-a0tgis8rp6q7d7ut9skt8q14a6oup0p6.apps.googleusercontent.com',
      },
      logoUrl: '/static/images/logo_course.ru.png',
      phonePlaceholder: '+7 ...',
      translationPriority: ['en', 'ru'],
      esHost: 'www.course.ru',
      locales: ['en', 'ru'],
      timezone: 'Europe/Moscow',
    },
  },
];
