import shortid from 'shortid';
import removeMarkdown from 'remove-markdown';
import { getCookie } from './cookies';

export const getRootUrl = () => {
  if (process.browser) {
    return `${window.location.protocol}//${window.location.hostname}:${window.location.port}/`;
  }

  return `http://localhost:${process.env.APP_PORT}/`;
};

export const cleanString = string => string && removeMarkdown(string).replace(/\r?\n|\r/g, ' ');

export const formatPrice = price =>
  price && price.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');

export const getSyncUser = () => ({
  isLoggedIn: !!getCookie('token', false),
  location: getCookie('location'),
  id: getCookie('sync'),
});

export const shortId = () =>
  shortid
    .generate()
    .toUpperCase()
    .replace(/[^a-z0-9]/gi, '');

export const toCamelCase = (str) => str.toLowerCase().replace(
  /([-_\s]+[a-z])/g,
  (group) => group.toUpperCase().replace(/[-_\s]+/g, '')
);