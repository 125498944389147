import {
  parseCookies as nokiesGetCookie,
  setCookie as nokiesSetCookie,
  destroyCookie as nookiesDestroyCookie,
} from 'nookies';

export const cookieExist = (label) => {
  const cookies = nokiesGetCookie(process.ctx);

  return typeof cookies[label] !== 'undefined'
};

export const getCookie = (label, parse = true) => {
  const cookies = nokiesGetCookie(process.ctx);
  let cookie = null;

  try {
    cookie = parse ? JSON.parse(cookies[label]) : cookies[label];
  } catch (e) {
    //    console.warn(`Cookie fetch ERROR: ${e}`);

    cookie = cookies[label] || null;
  }

  return cookie;
};

export const setCookie = (label, value, params = {}, stringify = true) => {
  try {
    return nokiesSetCookie(process.ctx, label, stringify ? JSON.stringify(value) : value, {
      maxAge: 30 * 24 * 60 * 60,
      path: '/',
      ...params,
    });
  } catch (e) {
    //    console.warn(`Failed to set cookie ${e}`);

    return null;
  }
};

export const destroyCookie = label => nookiesDestroyCookie(process.ctx, label);
